import classNames from 'classnames';
import logo from '../logo.svg';
import React, { useState } from 'react';

export default function NavBar() {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <nav className="bg-transparent shadow-sm">
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-16 items-center justify-between">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <button
                onClick={e => {
                  setOpen(prevOpen => !prevOpen);
                }}
                type="button"
                className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="absolute -inset-0.5"></span>
                <span className="sr-only">Open main menu</span>

                <svg
                  className="block h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>

                <svg
                  className="hidden h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex flex-shrink-0 items-center">
                <img className="w-24" src={logo} alt="AI CODER" />
              </div>

              {/* <div className="hidden sm:block ml-auto">
                <div className="flex space-x-4">
                  <a
                    href="#"
                    className="text-[#A19F9F] rounded-md px-3 py-2 text-sm font-medium"
                    aria-current="page"
                  >
                    How it works
                  </a>
                  <a
                    href="#"
                    className="text-[#A19F9F] rounded-md px-3 py-2 text-sm font-medium"
                  >
                    Features
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className={classNames({ hidden: !open })} id="mobile-menu">
          <div className="space-y-1 px-2 pb-3 pt-2">
            <a
              href="#"
              className="text-[#A19F9F] block rounded-md px-3 py-2 text-base font-medium"
              aria-current="page"
            >
              How it works
            </a>
            <a
              href="#"
              className="text-[#A19F9F] block rounded-md px-3 py-2 text-base font-medium"
            >
              Features
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
}
