import React from 'react';

export default function Input({
  placeholder,
  onChange,
  label,
  name,
  error,
  type = 'text',
  value,
}) {
  return (
    <div>
      {error && (
        <p className="font-medium leading-6 text-red-400 text-start text-sm">
          {error}
        </p>
      )}
      <div className="">
        <label className="text-xs text-[#787878]">{label}</label>
        <input
          className="border-[1px] w-full py-3 px-4 text-sm"
          placeholder={placeholder}
          onChange={onChange}
          name={name}
          type={type}
          value={value}
        />
      </div>
    </div>
  );
}
