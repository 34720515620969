import React, { useState } from 'react';
import logo from '../logo.svg';
import Input from './Input';
import Spinner from './Spinner';
import axios from '../config/axiosConfig';

export default function FormCard() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ email: '' });
  const [error, setError] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    const clientError = validateEmail(data.email);
    if (clientError) {
      setError(clientError);
      setLoading(false);
      return;
    }
    await doSubmit();
    setLoading(false);
  };

  function validateEmail(email) {
    const emailRegex = /^[\w.-]+@[\w.-]+\.[\w.-]+$/;
    return emailRegex.test(email) ? null : 'Invalid Email';
  }

  async function doSubmit() {
    try {
      const response = await axios.post('/create-checkout-session', {
        email: data.email,
      });
      if (response.data.url) {
        window.location = response.data.url;
      } else if (response.data.valid === false) {
        setError(response.data.message);
      } else {
        alert('Something went wrong, please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  function handleChange(e) {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  return (
    <div className="py-8 px-9 w-full bg-white rounded-2xl">
      <img src={logo} className="mb-4" alt="logo" />
      <p className="text-sm mb-4">
        Enter your email and we will send you an email with API KEY after
        payment is completed.
      </p>
      <form onSubmit={handleSubmit} onChange={handleChange}>
        <Input
          name="email"
          label="Enter Your Email"
          error={error}
          placeholder="email@email.com"
        />
        <button className="bg-[#3794FF] text-sm font-semibold block w-full py-3 text-white mt-4">
          {loading ? <Spinner /> : 'Proceed To Payment'}
        </button>
      </form>
    </div>
  );
}
