import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home';
import axios from './config/axiosConfig';
import './index.css';

const router = createBrowserRouter([
  {
    path: '/',
    loader: async ({ request }) => {
      const session_id = new URL(request.url).searchParams.get('session_id');
      const success = new URL(request.url).searchParams.get('success');

      if (session_id && success) {
        const response = await axios.get(`/success?session_id=${session_id}`);
        response.data.success = success;

        return response.data;
      } else {
        return { success: '0', customerEmail: '' };
      }
    },
    element: <Home />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RouterProvider router={router} />);
