import React from 'react';
import NavBar from '../components/NavBar';
import FormCard from '../components/FormCard';
import { useLoaderData } from 'react-router-dom';
import Success from './Success';

export default function Home() {
  const data = useLoaderData();

  return (
    <>
      {data.success && data.success === '1' ? (
        <Success customerEmail={data.customerEmail} />
      ) : (
        <div className="bg-mainBackground">
          <NavBar />
          <div className="container mx-auto min-h-[90vh] flex flex-col justify-center">
            <div className="w-full sm:w-8/12 md:w-6/12 lg:5/12 mx-auto">
              <FormCard />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
