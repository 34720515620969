import React from 'react';
import NavBar from '../components/NavBar';
import InfoCard from '../components/InfoCard';

export default function Success({ customerEmail }) {
  return (
    <div className="bg-mainBackground">
      <NavBar />
      <div className="container mx-auto min-h-[90vh] flex flex-col justify-center">
        <div className="w-full sm:w-8/12 md:w-6/12 lg:5/12 mx-auto">
          {customerEmail ? (
            <InfoCard email={customerEmail} />
          ) : (
            <div className="py-8 px-9 w-full bg-white rounded-2xl">
              <h3 className="text-xl font-bold text-center">
                Error Occured, please Try Later
              </h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
