import React from 'react';
import emailIcon from '../assets/Emailicon.png';

const InfoCard = ({ email }) => (
  <div className="py-8 px-9 w-full bg-white rounded-2xl">
    <img src={emailIcon} className="mb-4 mx-auto" alt="Email Icon" />
    <h3 className="text-xl font-bold text-center mb-4">Check your email</h3>
    <p className="text-sm mb-4 text-center">API Key sent to {email}</p>
  </div>
);

export default InfoCard;
