const Spinner = ({ width = 20, height = 20, color = '#dedede' }) => {
  return (
    <div className={`spinner-container`}>
      <div
        className="spinner"
        style={{ width: width, height: height, borderLeftColor: color }}
      ></div>
    </div>
  );
};

export default Spinner;
